import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Container,
  TableBody,
  LinearProgress,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
// date-fns
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// _mock
import { _roles } from 'src/_mock';
// hooks
// components
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
//
import { usePaymentActions, usePaymentData } from 'src/context/providers/PaymentContext';
//
import { getColorForStatus } from 'src/custom_components/Table/helper/getColorStatus';
import PropTypes from 'prop-types';
import UserTableRow from '../../custom_components/Table/customer-table-row';
import UserTableToolbar from '../../custom_components/Table/customer-table-toolbar';
import UserTableFiltersResult from '../../custom_components/Table/customer-table-filters-result';
import { paymentStatus } from './view/data';
import InvoiceDrawerComponent from './invoice-drawer-component';

export default function TcTable({ STATUS_OPTIONS, TABLE_HEAD, defaultFilters, tableNames }) {
  const table = useTable();
  const settings = useSettingsContext();
  const router = useRouter();
  const tableData = usePaymentData()?.invoices;
  const tableDetails = usePaymentData() || { invoicesTotal: 0, page: 1, invoiceTake: 25 };
  const [aRow, setARow] = useState({});
  const { getPaymentsInvoices } = usePaymentActions();

  const [filters, setFilters] = useState(defaultFilters);
  const [dataFiltered, setDataFiltered] = useState(tableData);

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.payment.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event) => {
      handleFilters('status', event.target.value);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const refetchData = useCallback(() => {
    getPaymentsInvoices(filters);
  }, [filters, getPaymentsInvoices]);

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      if (table.orderBy) {
        const aValue =
          a[table.orderBy] !== undefined && a[table.orderBy] !== null ? a[table.orderBy] : '';
        const bValue =
          b[table.orderBy] !== undefined && b[table.orderBy] !== null ? b[table.orderBy] : '';

        if (typeof aValue === 'string') {
          if (aValue.toLowerCase() < bValue.toLowerCase()) {
            return table.order === 'asc' ? -1 : 1;
          }
          if (aValue.toLowerCase() > bValue.toLowerCase()) {
            return table.order === 'asc' ? 1 : -1;
          }
        } else {
          if (aValue < bValue) {
            return table.order === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return table.order === 'asc' ? 1 : -1;
          }
        }
        return 0;
      }
      return 0;
    });
    setDataFiltered(sortedData);
  }, [table.order, table.orderBy, tableData]);

  const { payment_id } = useParams();

  useEffect(() => {
    if (payment_id) {
      const row = tableData?.find((item) => item.id === payment_id);
      if (row) {
        setARow(row);
        setIsDrawerOpen(true);
      } else {
        getPaymentsInvoices({ id: payment_id });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tableData, payment_id]);

  useEffect(() => {
    getPaymentsInvoices(filters);
  }, [filters, getPaymentsInvoices]);

  return (
    <div>
      {!dataFiltered.length ? <LinearProgress color="success" /> : ''}{' '}
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {Object.keys(aRow).length > 0 && (
          <InvoiceDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
            refetchData={refetchData}
          />
        )}
        <CustomBreadcrumbs
          heading="B2B Invoices"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <FormControl variant="outlined" sx={{ m: 2, minWidth: 200 }}>
            <InputLabel id="status-select-label">B2B Invoices status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={filters.status || 'all'}
              onChange={handleFilterStatus}
              label="B2B Invoices status"
            >
              {paymentStatus.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            style={{ marginLeft: '15px' }}
            sx={{
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={getColorForStatus(tab.value)}
                  >
                    {tab.value === 'all' && tableDetails.invoicesTotal}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <UserTableToolbar filters={filters} onFilters={handleFilters} roleOptions={_roles} />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered?.map((row) => row.id)
                    )
                  }
                />

                <TableBody onClick={handleDrawerToggle}>
                  {dataFiltered
                    ?.slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    ?.map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        deleteshow={false}
                        onEditRow={() => handleEditRow(row.id)}
                        handleDrawerToggle={handleDrawerToggle}
                        setARow={setARow}
                        tableNames={tableNames}
                        showOptions={false}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                  />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tableDetails.invoicesTotal}
            page={tableDetails.invoicePage - 1}
            rowsPerPage={tableDetails.invoiceTake}
            onPageChange={(event, newPage) => {
              setFilters((prevState) => ({
                ...prevState,
                page: newPage + 1,
              }));
            }}
            onRowsPerPageChange={(event) => {
              setFilters((prevState) => ({
                ...prevState,
                invoiceTake: parseInt(event.target.value, 10),
                page: 1,
              }));
            }}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  STATUS_OPTIONS: PropTypes.array,
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
};
