// PalletContext.js
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const PalletContext = createContext();
const PalletActionsContext = createContext();
const PalletDetailsContext = createContext();

export const PalletProvider = ({ children }) => {
  const [Palletes, setPalletes] = useState([]);
  const [palletrecordDetails, setPalletRecordDetails] = useState({});
  // eslint-disable-next-line
  const [selectedPallet, setSelectedPallet] = useState([]);

  const createPallet = async (newPallet) => {
    try {
      const response = await apiService.post('/pallets', newPallet, {
        headers: {
          timeout: 10000,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getPalletes();
      toast.success('Pallet created!');
    } catch (error) {
      toast.error('Error creating Pallet');
      console.error('Error creating Pallet:', error);
    }
  };

  const deletePallet = async (PalletId) => {
    try {
      await apiService.put(
        '/pallets/update/soft',
        { ids: PalletId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setPalletes((prevPalletes) => prevPalletes.filter((Pallet) => !PalletId.includes(Pallet.id)));
      toast.success('Pallet deleted!');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const updatePallet = async (PalletId, updatedPallet) => {
    try {
      // Simulate API call to update Pallet
      const response = await apiService.put(`/pallets/${PalletId}`, updatedPallet, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getPalletes();
      toast.success('Pallet updated!');
    } catch (error) {
      toast.error('Error updating Pallet!');
      console.error('Error updating Pallet:', error);
    }
  };
  const getPalletes = async (filters = {}) => {
    // Remove 'role' from filters if it is an empty array
    const effectiveFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (key === 'role' && Array.isArray(value) && value.length === 0) {
        return acc; // Skip adding to the accumulator
      }
      acc[key] = value; // Otherwise, add to the accumulator
      return acc;
    }, {});

    const query = new URLSearchParams(effectiveFilters).toString();

    try {
      // Simulate API call to get Pallets
      const response = await apiService.get(`/pallets?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Check if response has data and pallets field
      const data = response.data || {};
      const pallets = data.pallets || [];

      // Set the state for pallets and record details
      setPalletRecordDetails({
        page: data.page || 1,
        take: data.take || 5,
        total: data.total || pallets.length,
        totalPages: Math.ceil((+data.total || pallets.length) / (+data.take || 5)),
      });

      setPalletes(pallets);

      // Return the necessary values (you can still return these)
      return {
        pallets,
        page: data.page || 1,
        take: data.take || 5,
        total: data.total || pallets.length,
        totalPages: Math.ceil((+data.total || pallets.length) / (+data.take || 5)),
      };
    } catch (error) {
      console.error('Error fetching Palletes:', error);

      // Handle unauthorized error
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }

      // Return an empty response in case of an error
      return {
        pallets: [],
        page: 1,
        take: 5,
        total: 0,
        totalPages: 0,
      };
    }
  };

  const getPalletById = async (PalletId) => {
    try {
      const response = await apiService.get(`/pallets/${PalletId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSelectedPallet(data);
      return data;
    } catch (error) {
      console.error('Error fetching Pallet by ID:', error);
      throw error;
    }
  };

  // useEffect(() => {
  //   getPalletes();
  // }, []);

  const createPalletCallback = useCallback(createPallet, []);
  const deletePalletCallback = useCallback(deletePallet, []);
  const updatePalletCallback = useCallback(updatePallet, [updatePallet]);
  const getPalletesCallback = useCallback(getPalletes, []);
  const PalletActionsValue = useMemo(
    () => ({
      createPallet: createPalletCallback,
      deletePallet: deletePalletCallback,
      updatePallet: updatePalletCallback,
      getPalletes: getPalletesCallback,
      getPalletById,
    }),
    [createPalletCallback, deletePalletCallback, updatePalletCallback, getPalletesCallback]
  );

  const PalletesValue = useMemo(
    () => ({
      Palletes,
      palletrecordDetails,
    }),
    [Palletes, palletrecordDetails]
  );

  return (
    <PalletContext.Provider value={PalletesValue}>
      <PalletActionsContext.Provider value={PalletActionsValue}>
        <PalletActionsContext.Provider value={PalletActionsValue}>
          {children}
        </PalletActionsContext.Provider>
      </PalletActionsContext.Provider>
    </PalletContext.Provider>
  );
};

export const usePalletData = () => useContext(PalletContext);
export const usePalletActions = () => useContext(PalletActionsContext);
export const usePalletDetails = () => useContext(PalletDetailsContext);

PalletProvider.propTypes = {
  children: PropTypes.element,
};
