import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { apiService } from 'src/utils/apiServices';
import { jsPDF } from 'jspdf';
import gzavniliLogo from '../../assets/added_icons/gzavnili-logo.png';

/**
 * cropCanvas:
 * Trims away pure-white (or near-white) margins from the canvas edges.
 * Adjust 'tolerance' if your background isn't perfectly white (0 = exact white).
 */
function cropCanvas(canvas, tolerance = 0) {
  const ctx = canvas.getContext('2d');
  const { width, height } = canvas;
  const imageData = ctx.getImageData(0, 0, width, height).data;

  let top = null;
  let left = null;
  let right = null;
  let bottom = null;

  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      const index = (y * width + x) * 4;
      const r = imageData[index];
      const g = imageData[index + 1];
      const b = imageData[index + 2];
      const a = imageData[index + 3];

      // If this pixel is NOT pure white (within tolerance), update boundaries
      if (!(r >= 255 - tolerance && g >= 255 - tolerance && b >= 255 - tolerance && a === 255)) {
        if (top === null) top = y;
        if (left === null || x < left) left = x;
        if (right === null || x > right) right = x;
        bottom = y;
      }
    }
  }

  // If the entire canvas was white, return the original (no cropping)
  if (top === null) return canvas;

  const cropWidth = right - left + 1;
  const cropHeight = bottom - top + 1;

  const croppedCanvas = document.createElement('canvas');
  croppedCanvas.width = cropWidth;
  croppedCanvas.height = cropHeight;
  const croppedCtx = croppedCanvas.getContext('2d');

  croppedCtx.drawImage(canvas, left, top, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

  return croppedCanvas;
}

const InvoicePerID = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const access_token = Cookies.get("access_token");
  const userData = JSON.parse(localStorage.getItem('user'));

  // This ref includes the ENTIRE invoice (including the button):
  const invoiceRef = useRef(null);

  // Fetch data
  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await apiService.get(`/transactions/invoice/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setInvoice(response.data);
      } catch (err) {
        // setError(err.message || 'An error occurred');
      } finally {
        // setLoading(false);
      }
    };
    fetchInvoice();
  }, [id]);

  // Handle PDF Download
  const handleDownloadPDF = async () => {
    try {
      // 1) Capture the invoice, ignoring elements with class "btn-standard-icon-text"
      const originalCanvas = await html2canvas(invoiceRef.current, {
        scale: 2,
        ignoreElements: (element) => element.classList?.contains('btn-standard-icon-text'),
      });

      // 2) Crop out any pure-white margins
      const croppedCanvas = cropCanvas(originalCanvas, 0 /* tolerance */);

      // 3) Convert cropped canvas to an image
      const imgData = croppedCanvas.toDataURL('image/png');

      // 4) Create a multi-page PDF (A4)
      // eslint-disable-next-line
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Scale cropped canvas to fit PDF width
      const ratio = pdfWidth / croppedCanvas.width;
      const scaledWidth = pdfWidth;
      const scaledHeight = croppedCanvas.height * ratio;

      let remainingHeight = scaledHeight;
      let positionY = 0;

      // Place the entire scaled image on the first page
      pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
      remainingHeight -= pdfHeight;

      // 5) Add more pages if the image is taller than one page
      while (remainingHeight > 0) {
        positionY = scaledHeight - remainingHeight; // how much is "above" the new page
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, -positionY, scaledWidth, scaledHeight);
        remainingHeight -= pdfHeight;
      }

      // 6) Download the PDF
      pdf.save(`gzavnili_invoice${invoice?.order_id_external}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
    }
  };

  return (
    <div className="invoice" ref={invoiceRef}>
      <div className="cont">
        {/* HEADER */}
        <div className="header">
          <div className="left">
            <div className="top">
              <div className="mt">ინვოისი</div>
              <div className="st">{invoice?.order_id_external}</div>
            </div>
            <div className="bottom">
              <div className="i-date">
                ინვოისის თარიღი:{' '}
                <div className="bld">
                  {(() => {
                    const d = new Date(invoice?.created_at);
                    return `${d.getDate()} ${d.toLocaleString('default', {
                      month: 'long',
                    })}, ${d.getFullYear()}`;
                  })()}
                </div>
              </div>
            </div>
          </div>
          <img src={gzavniliLogo} alt="Gzavnili Logo" className="right" />
        </div>

        {/* BODY */}
        <div className="body">
          <div className="ff">
            <div className="left">
              <div className="ttxt">მყიდველი</div>
              <div className="info-b-c">
                <div className="info-b">
                  <div className="left-b">დასახელება:</div>
                  <div className="right-b">{userData.company_info}</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ტელეფონი:</div>
                  <div className="right-b">{userData.phone}</div>
                </div>
                <div className="info-b">
                  <div className="left-b">მეილი:</div>
                  <div className="right-b">{userData.email}</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ოთახი:</div>
                  <div className="right-b">{userData.room_number}</div>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="ttxt">გამყიდველი</div>
              <div className="info-b-c">
                <div className="info-b">
                  <div className="left-b">დასახელება:</div>
                  <div className="right-b">შპს Gzavnili ge</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ანგ. ნომერი:</div>
                  <div className="right-b">GE95TB7525736020100006GEL</div>
                </div>
                <div className="info-b">
                  <div className="left-b">საიდ კოდი:</div>
                  <div className="right-b">454345201m</div>
                </div>
                <div className="info-b">
                  <div className="left-b">მეილი:</div>
                  <div className="right-b">support@gzavnili.com</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ტელეფონი:</div>
                  <div className="right-b">0322 2 25 56 67</div>
                </div>
                <div className="info-b">
                  <div className="left-b">მისამართი:</div>
                  <div className="right-b">ქ. თბილისი, საბურთალოს რაიონი ვინცაძის ქ. N43 ბ, 4ა</div>
                </div>
              </div>
            </div>
          </div>

          <div className="sf">
            <div className="header-t">
              <div className="tbs">თრექინგი</div>
              <div className="tbs">წონა</div>
              <div className="tbs">საჰაერო გადაზიდვა</div>
            </div>
            <div className="body-t">
              {invoice?.orders.map((order) => (
                <div className="tbd-wrap" key={order.tracking_number}>
                  <div className="tbd">
                    <div className="top-t">{order?.tracking_number}</div>
                    <div className="bottom-t">{order?.name}</div>
                  </div>
                  <div className="tbd">{order?.weight} კგ</div>
                  <div className="tbd">{order?.price_gz} ₾</div>
                </div>
              ))}
            </div>
          </div>

          <div className="tf">
            <div className="left" />
            <div className="right">
              <div className="psum-cont">
                <div className="psum-l">
                  {/* {Returntext(contentManagementData, "air_freight_fee", lang)} */} საჰაერო
                  ტრანსპორტაცია
                </div>
                <div className="psum-r">{invoice?.gzavniliPrice} ₾</div>
              </div>
              {invoice?.customFeeTotal > 0 && (
                <div className="psum-cont">
                  <div className="psum-l">
                    {/* {Returntext(contentManagementData, "PBTDS", lang)} */}განბაჟება
                  </div>
                  <div className="psum-r">{invoice?.customFeeTotal} ₾</div>
                </div>
              )}
              {invoice?.service_fee > 0 && (
                <div className="psum-cont">
                  <div className="psum-l">
                    მომსახურების საფასური
                    {/* {Returntext(contentManagementData, "PBTDP", lang)} */}
                  </div>
                  <div className="psum-r">{invoice?.service_fee} ₾</div>
                </div>
              )}
              <div className="psum-cont">
                <div className="psum-l">
                  ტრანსპორტაცია
                  {/* {Returntext(contentManagementData, "PBTD", lang)} */}
                </div>
                <div className="psum-r">{invoice?.transportation}.00 ₾</div>
              </div>
              <div className="psum-cont">
                <div className="psum-l">სულ გადასახდელი</div>
                <div className="psum-r">{invoice?.total} ₾</div>
              </div>
            </div>
          </div>
        </div>

        {/* The download button is in the same container, 
            but we'll skip it in HTML2Canvas by ignoring className="btn-standard-icon-text". */}
        <div className="btm">
          {/* <BtnStandardIconText
            mode=""
            txt="ინვოისის ჩამოტვირთვა"
            disabled={false}
            size="standard"
            icon={true}
            mainColor="green"
            iconPosition={"right"}
            // svg={<DownloadIcon />}
            onClick={handleDownloadPDF}
          /> */}
          <button type="button" onClick={handleDownloadPDF}>
            ინვოისის ჩამოტვირთვა
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicePerID;
