// @mui
import {
  Button,
  MenuItem,
  TableRow,
  Checkbox,
  TableCell,
  IconButton,
  ListItemText,
} from '@mui/material';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// props
import PropTypes from 'prop-types';

// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { format } from 'date-fns';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { getColorForStatus } from './helper/getColorStatus';

// ----------------------------------------------------------------------
export default function UserTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  tableNames,
  handleDrawerToggle,
  setARow,
  deleteshow = true,
  ignoreCountry = false,
  showOptions = true,
}) {
  const confirm = useBoolean();
  const popover = usePopover();

  const chekBoxHandler = (e) => {
    e.stopPropagation();
    onSelectRow(e);
  };

  const ActionsClickHandlerOpen = (e) => {
    e.stopPropagation();
    popover.onOpen(e);
  };

  const ActionsClickHandlerClose = (e) => {
    e.stopPropagation();
  };

  const tableConfig = {
    showCheckBox: true,
  };

  function renderTable() {
    return tableNames.map((i, index) => {
      switch (i.id) {
        case 'name':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {row.name}
            </TableCell>
          );
        case 'NeedsCustom': {
          return (
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={index}>
              {row.NeedsCustom ? 'true' : 'false'}
            </TableCell>
          );
        }

        case 'vatPaymentAuto': {
          return (
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={index}>
              {row.vatPaymentAuto ? 'true' : 'false'}
            </TableCell>
          );
        }
        case 'marketing_permissions': {
          return (
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={index}>
              {row.marketing_permissions ? 'YES' : 'NO'}
            </TableCell>
          );
        }
        case 'decSum': {
          return (
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={index}>
              {row?.declaration?.reduce((acc, curr) => acc + parseFloat(curr.price), 0)}$
            </TableCell>
          );
        }
        case 'pallet': {
          return (
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={index}>
              {row?.pallet?.tracking}
            </TableCell>
          );
        }
        case 'country':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {ignoreCountry ? row.country : row.country.name}
            </TableCell>
          );
        case 'totalWeight':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {row?.totalWeight?.toFixed(2)}
            </TableCell>
          );

        case 'airwayBill':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {row?.airwayBill?.tracking}
            </TableCell>
          );
        case 'arrivalDate':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {row?.airwayBill?.airway_date
                ? format(new Date(row.airwayBill.airway_date), 'dd/MM/yyyy')
                : '-'}
            </TableCell>
          );
        case 'declaration_status':
          return (
            <TableCell sx={{ textAlign: 'center' }} key={index}>
              {row?.declaration_status === 'DECLERED' ? (
                <Iconify
                  icon="ic:baseline-check-box"
                  sx={{ color: 'green' }}
                  width={27}
                  height={27}
                />
              ) : (
                ''
              )}
            </TableCell>
          );

        case 'status':
          return (
            <TableCell key={index}>
              <Label variant="soft" color={getColorForStatus(row.status)}>
                {row.status}
              </Label>
            </TableCell>
          );
        case 'first_name':
          return (
            <TableCell key={index}>
              <ListItemText
                primary={row.first_name}
                key={i.id}
                secondary={row.first_name_ka}
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
              />
            </TableCell>
          );
        case 'room_number':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {row.customer?.room_number}
            </TableCell>
          );
        case 'tracking_numbers':
          return (
            <TableCell
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '150px',
              }}
              key={index}
            >
              {row?.tracking_numbers}
            </TableCell>
          );

        case 'room_number_customer':
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={index}>
              {row?.room_number}
            </TableCell>
          );

        case 'last_name':
          return (
            <TableCell key={index}>
              <ListItemText
                primary={row.last_name}
                key={i.id}
                secondary={row.last_name_ka}
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
              />
            </TableCell>
          );

        default:
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={i.id}>
              {row[i.id]}
            </TableCell>
          );
      }
    });
  }
  return (
    <>
      <TableRow
        hover
        selected={selected}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleDrawerToggle();
          setARow(row);
        }}
      >
        {tableConfig.showCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={chekBoxHandler} />
          </TableCell>
        )}

        {renderTable()}

        {showOptions ? (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={ActionsClickHandlerOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        ) : null}
      </TableRow>

      {deleteshow ? (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
          onClick={ActionsClickHandlerClose}
        >
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        </CustomPopover>
      ) : (
        ''
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  tableNames: PropTypes.array,
  handleDrawerToggle: PropTypes.func,
  setARow: PropTypes.func,
  deleteshow: PropTypes.bool,
  ignoreCountry: PropTypes.bool,
  showOptions: PropTypes.bool,
};
