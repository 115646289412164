import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import GitHubVersion from 'src/custom_components/GitHubVersion/gitHubVersion';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;
  //
  // const PRIMARY_MAIN = theme.palette.primary.main;
  //
  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ display: 'flex', alignItems:'center', justifyContent:'space-between'}}>
      
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36.62 11.8374C36.62 11.907 36.5727 11.9598 36.504 11.9722L28.9821 13.0322C29.3932 14.3663 29.8584 16.4033 29.3054 18.8154C28.8402 20.8389 27.7104 22.5154 26.0367 23.6642C24.2762 24.8702 22.3782 25.2105 21.0446 25.1611L12.4009 33.7819H7.73438L17.2195 24.3234C15.7518 23.7361 14.3337 22.8568 13.1026 21.744C7.30298 16.5133 7.31875 9.066 8.3978 3.75L22.2036 15.9013V20.1573L11.1518 10.4304C11.2621 12.9514 12.0709 16.5122 15.2438 19.3802C17.3569 21.2881 20.0331 22.2605 22.2307 21.9102C24.2412 21.5924 25.7551 20.1517 26.1808 18.1517C26.6528 15.9328 25.9589 13.9283 24.1275 12.3618L26.2428 10.2552L36.5051 11.7049C36.5738 11.7139 36.6211 11.7689 36.6211 11.8374H36.62Z"
            fill="#5BE49B"
          />
        </svg>
      </Box>
      <GitHubVersion />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
