// @mui
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import '../../styles/dashboard.scss';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';
//
import SwitcherComponent from 'src/custom_components/switcher/Switcher';
import { API_BASE_URL, apiService } from 'src/utils/apiServices';
import { TIME_SLOT_FOR_ORDER } from '../order/view/data';

export default function TcTable({
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  kanbanInput,
  STATUS_ORDER,
}) {
  // eslint-disable-next-line
  const [alertData, setAlertData] = useState(null);
  const settings = useSettingsContext();
  const [liveOrderData, setLiveOrderData] = useState([]);
  // const [aRow, setARow] = useState({});
  // const [newData, setNewData] = useState()

  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const handleDrawerToggle = () => {
  //   setIsDrawerOpen(!isDrawerOpen);
  // };
  const [date, setDate] = useState('today');

  // const closeAlert = () => {
  //   setAlertData(null);
  // };
  useEffect(() => {
    const liveOrders = () => {
      const es = new EventSource(`${API_BASE_URL}/orders/liveOrders`);

      es.onopen = () => console.log('>>> Connection opened!');

      es.onerror = (e) => {
        console.error('SSE error!', e);
        es.close();
      };

      es.addEventListener('orderUpdate', (e) => {
        try {
          const data = JSON.parse(e.data);
          setLiveOrderData(data);
        } catch (error) {
          console.error('Failed to parse message data as JSON', error);
        }
      });

      return () => es.close();
    };

    const closeEventSource = liveOrders();
    return () => closeEventSource();
  }, []);
  const ordersForFirstLoad = async (id) => {
    try {
      const response = await apiService.get(`/orders/firstLoadLiveOrders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10000,
      });
      const data = await response.data;
      setLiveOrderData(data);
    } catch (error) {
      console.error('Error fetching order:', error);
      throw error;
    }
  };

  useEffect(() => {
    ordersForFirstLoad();
  }, []);
  const [timeSlot, setTimeSlot] = useState('');

  const handleTimeSlotChange = (event) => {
    setTimeSlot(event.target.value);
  };

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {/* {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )} */}
        {/* {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )} */}
        <CustomBreadcrumbs heading="Live Orders" sx={{ padding: '0px 0.5%' }} />
        <div
          style={{
            padding: '1% 0.5%',
            display: 'flex',
            alignItems: 'center',
            gap: '100px',
          }}
        >
          <SwitcherComponent setDate={setDate} />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Time Slot</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={timeSlot}
                label="Time Slot"
                onChange={handleTimeSlotChange}
              >
                {TIME_SLOT_FOR_ORDER.map((i, index) => (
                  <MenuItem value={i.value} key={index}>
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="select_containers" style={{ gap: '10px' }}>
          {STATUS_ORDER.filter((i) => i.label !== 'All').map((item, ind) => (
            <div
              className="child_kanban aligh_row_items"
              key={ind}
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#f4f6f8', padding: '5px' }
                  : { backgroundColor: '#2D353D', padding: '5px' }
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '10px 0px',
                  padding: '10px',
                }}
              >
                <Typography variant="h6" style={{ color: '#2aa76f', fontSize: '17px' }}>
                  {item.label} -{' '}
                  {liveOrderData?.[date]?.[item.value]?.filter(
                    (order) =>
                      timeSlot === 'all' || timeSlot === '' || order.order_timeSlot === timeSlot
                  )?.length || 0}
                </Typography>
              </Box>

              <Box
                style={{
                  backgroundColor: settings.themeMode === 'light' ? '#f4f5f8' : '#2d353d',
                  height: '60vh',
                  overflowX: 'hidden',
                }}
              >
                {liveOrderData?.[date]?.[item.value]
                  ?.filter(
                    (order) =>
                      timeSlot === 'all' || timeSlot === '' || order.order_timeSlot === timeSlot
                  )
                  ?.map((i, index) => (
                    <Box
                      className="box_items_slot"
                      key={index}
                      style={{
                        alignItems: 'center',
                        backgroundColor: settings.themeMode === 'light' ? '#ffffff' : '#161C24',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '5px',
                          }}
                        >
                          <div className="light_text" style={{ fontSize: '0.8rem' }}>
                            {i.order_tracking_number}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div className="light_text" style={{ fontSize: '0.8rem' }}>
                            {i?.customer_room_number}
                          </div>
                        </div>
                      </div>
                    </Box>
                  ))}
              </Box>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
  STATUS_ORDER: PropTypes.array,
};
