// BoxContext.js
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const BoxContext = createContext();
const BoxActionsContext = createContext();
const BoxDetailsContext = createContext();

export const BoxProvider = ({ children }) => {
  const [Boxes, setBoxes] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [boxRecordDetails, setBoxRecordDetails] = useState({});
  const createBox = async (newBox) => {
    try {
      const response = await apiService.post('/boxes', newBox, {
        headers: {
          timeout: 10000,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getBoxes();
      toast.success('box created!');
    } catch (error) {
      console.error('Error creating Box:', error);
      toast.error('Error creating Box!');
    }
  };

  const deleteBox = async (BoxId) => {
    try {
      await apiService.put(
        '/boxes/update/soft',
        { ids: BoxId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast.success('box deleted!');
      setBoxes((prevBoxes) => prevBoxes.filter((Box) => !BoxId.includes(Box.id)));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const updateBox = async (BoxId, updatedBox) => {
    try {
      // Simulate API call to update Box
      const response = await apiService.put(`/boxes/${BoxId}`, updatedBox, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setBoxes((prevBoxes) =>
        prevBoxes.map((Box) => (Box.id === BoxId ? { ...Box, ...data } : Box))
      );
      toast.success('box updated!');
    } catch (error) {
      toast.success('Error updating Box');
      console.error('Error updating Box:', error);
    }
  };

  // const getBoxes = async () => {
  //   try {
  //     const response = await apiService.get(`/boxes`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     });
  //     const data = await response.data;
  //     setBoxes(data);
  //     return data;
  //   } catch (error) {
  //     console.error('Error fetching Boxes:', error);
  //     if (error.response.status === 401) {
  //       localStorage.removeItem('token');
  //       redirect('/');
  //     }
  //     return error;
  //   }
  // };

  const getBoxById = async (BoxId) => {
    try {
      const response = await apiService.get(`/boxes/${BoxId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSelectedBox(data);
      return data;
    } catch (error) {
      console.error('Error fetching Box by ID:', error);
      throw error;
    }
  };

  const getBoxes = async (filters = {}) => {
    // Remove 'role' from filters if it is an empty array
    const effectiveFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (key === 'role' && Array.isArray(value) && value.length === 0) {
        return acc; // Skip adding to the accumulator
      }
      acc[key] = value; // Otherwise, add to the accumulator
      return acc;
    }, {});

    const query = new URLSearchParams(effectiveFilters).toString();

    try {
      // Simulate API call to get boxes
      const response = await apiService.get(`/boxes?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Check if response has data and boxes field
      const data = response.data || {};
      const boxes = data.boxes || [];

      // Set the state for boxes and record details
      setBoxRecordDetails({
        page: data.page || 1,
        take: data.take || 5,
        total: data.total || boxes.length,
        totalPages: Math.ceil((+data.total || boxes.length) / (+data.take || 5)),
      });

      setBoxes(boxes);

      // Return the necessary values (you can still return these)
      return {
        boxes,
        page: data.page || 1,
        take: data.take || 5,
        total: data.total || boxes.length,
        totalPages: Math.ceil((+data.total || boxes.length) / (+data.take || 5)),
      };
    } catch (error) {
      console.error('Error fetching Palletes:', error);

      // Handle unauthorized error
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }

      // Return an empty response in case of an error
      return {
        boxes: [],
        page: 1,
        take: 5,
        total: 0,
        totalPages: 0,
      };
    }
  };

  // useEffect(() => {
  //   getBoxes();
  // }, []);

  const createBoxCallback = useCallback(createBox, []);
  const deleteBoxCallback = useCallback(deleteBox, []);
  const updateBoxCallback = useCallback(updateBox, [updateBox]);
  const getBoxesCallback = useCallback(getBoxes, []);

  const BoxActionsValue = useMemo(
    () => ({
      createBox: createBoxCallback,
      deleteBox: deleteBoxCallback,
      updateBox: updateBoxCallback,
      getBoxes: getBoxesCallback,
      getBoxById,
    }),
    [createBoxCallback, deleteBoxCallback, updateBoxCallback, getBoxesCallback]
  );

  const BoxesValue = useMemo(
    () => ({
      Boxes,
      boxRecordDetails,
    }),
    [Boxes, boxRecordDetails]
  );

  return (
    <BoxContext.Provider value={BoxesValue}>
      <BoxActionsContext.Provider value={BoxActionsValue}>
        <BoxDetailsContext.Provider value={selectedBox}>{children}</BoxDetailsContext.Provider>
      </BoxActionsContext.Provider>
    </BoxContext.Provider>
  );
};

export const useBoxData = () => useContext(BoxContext);
export const useBoxActions = () => useContext(BoxActionsContext);
export const useBoxDetails = () => useContext(BoxDetailsContext);

BoxProvider.propTypes = {
  children: PropTypes.element,
};
