import { Drawer, IconButton, Button } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import OrderList from 'src/custom_components/orderList/OrderList';
import { usePaymentActions } from 'src/context/providers/PaymentContext';
import { personalInformation } from './view/data';
import InputFieds from '../../custom_components/Froms/InputFields';
import '../../styles/dashboard.scss';

const InvoiceDrawerComponent = ({
  isDrawerOpen,
  handleDrawerToggle,
  aRow,
  setARow,
  refetchData,
}) => {
  // eslint-disable-next-line
  const [userData, setUserData] = useState({
    customer_room_number: aRow?.customer_room_number || '',
  });

  const { invoiceStatusChange } = usePaymentActions();
  const DrawerConfig = {
    SubNavigation: false,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: 'Manage Customer Information',
    SubNav: true,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      setARow({});
      handleDrawerToggle();
    }
  };
  const AcceptInvoice = async () => {
    try {
      await invoiceStatusChange(aRow.id, 'COMPLETED');
      setARow({});
      refetchData();
      handleDrawerToggle();
    } catch (error) {
      console.error('Error accepting invoice:', error);
    }
  };
  // const bearerToken = localStorage.getItem('token');
  // const invoiceFiles = aRow.orders
  //   ?.flatMap((order) => order.declarationFiles || [])
  //   ?.filter((file) => file.fileType === 'INVOICE');

  // const renderUserDeclarationFiles = (
  //   <>
  //     <CardHeader title="Invoice File" />
  //     <div style={{ position: 'relative' }}>
  //       <a
  //         href={`${API_BASE_URL}/s3/image/${invoiceFiles[0]?.name}?token=${bearerToken}`}
  //         download={invoiceFiles[0]?.name}
  //         style={{
  //           color: 'white',
  //         }}
  //         target="blank"
  //       >
  //         <Image
  //           key={invoiceFiles[0]?.id}
  //           src={fileIcon}
  //           ratio="1/1"
  //           disabledEffect
  //           sx={{
  //             borderRadius: 1.25,
  //             m: 2,
  //             width: 50,
  //             height: 50,
  //           }}
  //         />
  //       </a>
  //     </div>
  //   </>
  // );

  const RejectInvoice = async () => {
    try {
      await invoiceStatusChange(aRow.id, 'REJECTED');
      refetchData();
      setARow({});
      handleDrawerToggle();
    } catch (error) {
      console.error('Error rejecting invoice:', error);
    }
  };

  const handleOpenInNewTab = () => {
    const url = `/inv/invoice/${aRow.id}`;
    const fullUrl = `${window.location.origin}${url}`; // Generate full URL
    window.open(fullUrl, '_blank'); // Open in a new tab
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          setARow({});
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={() => {
          setARow({});
          handleDrawerToggle();
        }}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}
        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <IconButton
            onClick={() => {
              handleDrawerToggle();
              setARow({});
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {aRow.status === 'PENDING' || aRow.status === 'PENDINGONUSER' ? (
          <div style={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Button
              variant="contained"
              color="success"
              sx={{ width: '40%', float: 'right', margin: '5%' }}
              onClick={AcceptInvoice}
            >
              APPROVE
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ width: '40%', float: 'right', margin: '5%' }}
              onClick={RejectInvoice}
            >
              CANCEL
            </Button>
          </div>
        ) : (
          ''
        )}
        <OrderList aRow={aRow} />
        <Button
          variant="contained"
          color="success"
          sx={{ width: '50%', float: 'right', margin: '5%' }}
          onClick={handleOpenInNewTab}
        >
          SHOW INVOICE FILE
        </Button>
        <div>
          <InputFieds
            userData={aRow}
            heading="Personal Information"
            inputFields={personalInformation}
            setUserData={setUserData}
          />
        </div>
      </Drawer>
    </div>
  );
};

InvoiceDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
  refetchData: PropTypes.func,
};

export default InvoiceDrawerComponent;
