// @mui
import { Box, Container, Typography, Button } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import '../../styles/dashboard.scss';
import PropTypes from 'prop-types';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useState } from 'react';
import { toast } from 'react-toastify';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';
import SwitcherComponent from 'src/custom_components/switcher/Switcher';
//
import AlertReturnFunction from 'src/custom_components/Alert/AlertRetunrFunction';
import { useOrderActions, useOrderData } from 'src/context/providers/OrderContext';
import OrderDrawerComponent from '../order/order-drawer-component';

export default function TcTable({
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  kanbanInput,
  TIME_SLOT_FOR_ORDER,
}) {
  const [alertData, setAlertData] = useState(null);
  const { sendToDelivery, getOrderById } = useOrderActions();
  const settings = useSettingsContext();
  const tableData = useOrderData().ordersTimeSlot;
  const [date, setDate] = useState('today');
  const [selectedRows, setSelectedRows] = useState([]);

  // console.log(date==='today')
  const [aRow, setARow] = useState({});

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeAlert = () => {
    setAlertData(null);
  };
  const SendToDelivery = async () => {
    const userToken = localStorage.getItem('token');
    if (selectedRows.length && userToken) {
      try {
        await sendToDelivery(selectedRows, userToken);
      } catch (error) {
        toast.error('An error occurred while sending orders to delivery:!');
      }
    } else {
      toast.error('Choose Orders To Update!');
    }
  };

  const clickedShelf = (e) => {
    const selectedOrderID = e.currentTarget.getAttribute('data-clicked-id');
    if (selectedRows.includes(selectedOrderID)) {
      setSelectedRows(selectedRows.filter((id) => id !== selectedOrderID));
    } else {
      setSelectedRows([...selectedRows, selectedOrderID]);
    }
  };

  const openDrawer = (e) => {
    const selectedOrderID = e.currentTarget.getAttribute('data-order-id');
    getOrderById(selectedOrderID).then((data) => {
      if (data) {
        setARow(data);
        setIsDrawerOpen(true);
      }
    });
  };

  // console.log(tableData?.today['15:00-19:00'],'tableData')

  // const parseOrderDate = (orderDate) => {
  //   const formattedOrderDate = orderDate.replace(' at ', ' ').replace(' GMT+4', ' GMT+0400');
  //   return new Date(formattedOrderDate);
  // };

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )}
        {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <CustomBreadcrumbs heading="Order Pick UP" sx={{ padding: '0px 0.5%' }} />
        <div
          style={{
            padding: '1% 0.5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SwitcherComponent setDate={setDate} />
          <Button variant="contained" onClick={SendToDelivery} startIcon={<ShoppingCartIcon />}>
            PICK UP
          </Button>

          {/* <DatePickerValue setter={setter} value={date} setDate={setter} selectName="order_date" /> */}
        </div>
        <div className="select_containers" style={{ gap: '10px' }}>
          {TIME_SLOT_FOR_ORDER.filter((i) => i.label !== 'All').map((item, ind) => (
            <div
              className="child_kanban aligh_row_items"
              key={ind}
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#f4f6f8', padding: '5px' }
                  : { backgroundColor: '#2D353D', padding: '5px' }
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '10px 0px',
                  padding: '10px',
                }}
              >
                <AccessAlarmIcon style={{ color: '#2aa76f' }} />
                <Typography variant="h6" style={{ color: '#2aa76f', fontSize: '17px' }}>
                  {item.label}
                </Typography>
              </Box>

              <Box
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8', minHeight: '300px' }
                    : { backgroundColor: '#2d353d', minHeight: '300px' }
                }
              >
                {tableData?.[date]?.[item.value]
                  ? tableData?.[date]?.[item.value]?.map((i, index) => (
                      <Box
                        className="box_items_slot"
                        key={i.id}
                        style={
                          settings.themeMode === 'light'
                            ? { backgroundColor: '#ffffff', alignItems: 'center' }
                            : { backgroundColor: '#161C24', alignItems: 'center' }
                        }
                        sx={i.status === 'PICKED UP' ? { opacity: 0.5 } : {}}
                      >
                        <Box
                          onClick={openDrawer}
                          data-order-id={i.id}
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          }}
                        >
                          <EditIcon className="check_icons" />
                        </Box>
                        <Box
                          onClick={clickedShelf}
                          data-clicked-id={i.id}
                          sx={{
                            position: 'absolute',
                            right: 30,
                            top: 0,
                          }}
                        >
                          {i.status !== 'PICKED UP' &&
                            (selectedRows.includes(i.id) ? (
                              <CheckCircleOutlineIcon className="check_icons" />
                            ) : (
                              <RadioButtonUncheckedIcon className="check_icons" />
                            ))}
                        </Box>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: '5px',
                            }}
                          >
                            <div
                              className="light_text"
                              style={{
                                fontSize: '0.8rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '130px',
                              }}
                            >
                              ...{i?.tracking_number?.slice(-16)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '5px',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <div className="light_text" style={{ fontSize: '0.8rem' }}>
                              {i?.customer?.first_name_ka || i?.customer?.first_name}{' '}
                              {i?.customer?.last_name_ka || i?.customer?.last_name} {i?.shelf?.code}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '5px',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <div className="light_text" style={{ fontSize: '0.8rem' }}>
                              {i?.orderDetail?.address}
                            </div>
                          </div>
                        </div>
                      </Box>
                    ))
                  : null}
              </Box>
            </div>
          ))}
        </div>
        <Box
          style={
            settings.themeMode === 'light'
              ? {
                  backgroundColor: '#f4f5f8',
                  minHeight: '300px',
                  width: '40%',
                  paddingBottom: '20px',
                  border: '1px solid rgba(255, 255, 255)',
                  borderRadius: '20px',
                  color: 'rgb(42, 167, 111)',
                }
              : {
                  backgroundColor: '#2d353d',
                  minHeight: '300px',
                  width: '40%',
                  paddingBottom: '20px',
                  border: '1px solid rgba(255, 255, 255)',
                  borderRadius: '20px',
                  color: 'rgb(42, 167, 111)',
                }
          }
        >
          <CustomBreadcrumbs heading="ONWAY Orders" sx={{ padding: '10px' }} />
          {tableData?.onWay?.map((i, index) => (
            <Box
              className="box_items_slot"
              key={i.id}
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff', alignItems: 'center' }
                  : { backgroundColor: '#161C24', alignItems: 'center' }
              }
              sx={{ width: '90%', margin: '10px' }}
            >
              <Box
                onClick={openDrawer}
                data-order-id={i.id}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }}
              >
                <EditIcon className="check_icons" />
              </Box>
              <Box
                onClick={clickedShelf}
                data-clicked-id={i.id}
                sx={{
                  position: 'absolute',
                  right: 30,
                  top: 0,
                }}
              >
                {i.status !== 'PICKED UP' &&
                  (selectedRows.includes(i.id) ? (
                    <CheckCircleOutlineIcon className="check_icons" />
                  ) : (
                    <RadioButtonUncheckedIcon className="check_icons" />
                  ))}
              </Box>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '5px',
                  }}
                >
                  <div
                    className="light_text"
                    style={{
                      fontSize: '0.8rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px',
                    }}
                  >
                    ...{i?.tracking_number?.slice(-16)}
                  </div>{' '}
                  -
                  <div className="light_text" style={{ fontSize: '0.8rem' }}>
                    {i?.shelf?.code}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '5px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div className="light_text" style={{ fontSize: '0.8rem' }}>
                    {i?.orderDetail?.address}
                  </div>
                  -
                  <div className="light_text" style={{ fontSize: '0.8rem' }}>
                    {i?.customer?.first_name_ka || i?.customer?.first_name}{' '}
                    {i?.customer?.last_name_ka || i?.customer?.last_name}
                  </div>
                </div>
              </div>
            </Box>
          ))}
        </Box>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
  TIME_SLOT_FOR_ORDER: PropTypes.array,
};
