// import { USER_STATUS_OPTIONS } from 'src/_mock';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];
const STATUS_OPTIONS = [{ value: 'all', label: 'All' }];

const TABLE_HEAD = [
  { id: 'amount', label: 'amount' },
  { id: 'gzavniliPrice', label: 'gzavniliPrice' },
  { id: 'serviceFee', label: 'serviceFee' },
  { id: 'customFeeTotal', label: 'customFeeTotal' },
  { id: 'transportation', label: 'transportation' },
  { id: 'tracking_numbers', label: 'tracking_numbers' },
  { id: 'customer_room_number', label: 'room_number' },
  { id: 'order_id_external', label: 'invoice_number' },
  { id: 'status', label: 'Status', width: 200 },
];

const defaultFilters = {
  role: [],
};

const tableNames = [
  { id: 'amount', label: 'amount' },
  { id: 'gzavniliPrice', label: 'gzavniliPrice' },
  { id: 'serviceFee', label: 'serviceFee' },
  { id: 'customFeeTotal', label: 'customFeeTotal' },
  { id: 'transportation', label: 'transportation' },
  { id: 'tracking_numbers', label: 'tracking_numbers' },
  { id: 'customer_room_number', label: 'room_number' },
  { id: 'order_id_external', label: 'order_id_external' },
  { id: 'status', label: 'Status', width: 200 },
];

const status = ['ACTIVE', 'INACTIVE', 'SUSPENDED', 'CLOSED'];

const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
  SUSPENDED: ['#FFAB0029', '#B76E00'],
  CLOSED: ['#FF563029', '#B71D18'],

  PLACED: ['#22C55E29', '#118D57'],
  PROCESSING: ['#FF563029', '#B71D18'],
  SHIPPED: ['#FFAB0029', '#B76E00'],
  OUT_FOR_DELIVERY: ['#DDF4FA', '#1A6C9C'],
  DELIVERED: ['#919EAB29', '#637381'],
  RETURN_TO_SENDER: ['#919EAB29', '#637381'],
  DELAYED: ['#919EAB29', '#637381'],
  CANCELLED: ['#919EAB29', '#637381'],
  NO_TRACKING_OR_ROOM: ['#919EAB29', '#637381'],
  DELETED: ['#919EAB29', '#637381'],
  Awaiting: ['#919EAB29', '#637381'],
  'Received in US warehouse': ['#919EAB29', '#637381'],
  'Hold in US warehouse': ['#919EAB29', '#637381'],
  Damaged: ['#919EAB29', '#637381'],
  'On the way from US warehouse': ['#919EAB29', '#637381'],
  'Droped  in US airport facility': ['#919EAB29', '#637381'],
  'Air Shipped from US': ['#919EAB29', '#637381'],
  Delayed: ['#919EAB29', '#637381'],
  'Customs Clearance': ['#919EAB29', '#637381'],
  'Customs Expertise': ['#919EAB29', '#637381'],
  'Received in Tbilisi Warehouse': ['#919EAB29', '#637381'],
  'Out For Delivery': ['#919EAB29', '#637381'],
  'Failed Delivery Attempt': ['#919EAB29', '#637381'],
  Delivered: ['#919EAB29', '#637381'],
  Canceled: ['#919EAB29', '#637381'],
  'Unclear Order': ['#919EAB29', '#637381'],
  'Action Needed': ['#919EAB29', '#637381'],
  'Needs Custom Clearance': ['#919EAB29', '#637381'],
  'Deadline for the customs clearance completion ': ['#919EAB29', '#637381'],
  'Needs to be transferred to government ': ['#919EAB29', '#637381'],
  'Transferred to government': ['#919EAB29', '#637381'],
  'Lost Parcel': ['#919EAB29', '#637381'],
};
const paymentStatus = ['COMPLETED', 'REJECTED', 'REFUNDED', 'PENDING'];
const personalInformation = [
  {
    name: 'amount',
    width: '96%',
    label: 'Total Amount',
  },
  {
    name: 'customer_room_number',
    width: '96%',
    label: 'Room Number',
  },

  {
    name: 'gzavniliPrice',
    width: '96%',
    label: 'Gzavnili Price',
  },

  {
    name: 'serviceFee',
    width: '96%',
    label: 'serviceFee',
  },

  {
    name: 'transportation',
    width: '96%',
    label: 'transportation',
  },
  {
    name: 'customFeeTotal',
    width: '96%',
    label: 'customFeeTotal',
  },
  {
    name: 'order_id_external',
    width: '96%',
    label: 'Invoice Number',
  },
];

export {
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  status_colors,
  personalInformation,
  paymentStatus,
};
