import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Barcode from 'react-barcode';
import logo from '../../assets/added_icons/logo/logo_black.svg';

function ZebraPrintComponent({ i }) {
  const ref = useRef(null);
  const generateAndPrintPDF = async () => {
    if (ref.current) {
      try {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL('image/png');

        // Create a PDF with custom dimensions (10 cm x 14 cm converted to mm)

        // eslint-disable-next-line
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: [100, 150], // Width x Height in mm
        });

        // Scale the canvas to fit into the PDF
        const imgProps = pdf.getImageProperties(imgData);
        let imgWidth = imgProps.width;
        let imgHeight = imgProps.height;

        // Calculate the scale to fit the image inside the PDF page while maintaining aspect ratio
        const scaleX = 100 / imgWidth;
        const scaleY = 150 / imgHeight;
        const scale = Math.min(scaleX, scaleY); // Use the smallest scale to ensure the entire image fits
        imgWidth *= scale;
        imgHeight *= scale;

        // Optionally center the image
        const x = (100 - imgWidth) / 2;
        const y = (150 - imgHeight) / 2;

        // Add the scaled image to the PDF
        pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

        const pdfBlob = pdf.output('blob');
        const url = URL.createObjectURL(pdfBlob);
        const printWindow = window.open(url, '_blank');
        printWindow.onload = () => {
          try {
            printWindow.print();
          } catch (e) {
            console.error('Error triggering print dialog:', e);
          }
        };
      } catch (error) {
        console.error('Error generating or printing PDF:', error);
      }
    }
  };

  return (
    <>
      <Button variant="contained" onClick={generateAndPrintPDF} style={{ margin: '10px' }}>
        Print
      </Button>
      <div
        ref={ref}
        style={{
          position: 'absolute',
          left: '-10000px',
          width: '100mm',
          height: '140mm',
          padding: '2px 10px',
          backgroundColor: 'white', // Ensure background color is set
          color: 'black', // Ensure text color is set
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1px' }}
        >
          <p style={{ textAlign: 'center', margin: 0 }}>შეფუთვის ფურცელი</p>
          <img src={logo} alt="" width="30px" height="30px" />
        </div>
        <hr style={{ color: 'black', border: '0.1px solid black' }} />
        <div
          style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px 0px 0px' }}
        >
          <p style={{ fontSize: '20px', fontWeight: 'bold', margin: 0, textAlign: 'left' }}>
            #{i?.quickshipper?.internalID}
          </p>
          <p style={{ fontSize: '20px', fontWeight: 'bold', margin: 0 }}>
            {i?.quickshipper?.comment}
          </p>
        </div>
        <p style={{ margin: 0, textAlign: 'left' }}>მისამართი:</p>
        <p style={{ margin: 0, textAlign: 'left' }}>
          {i?.orderDetail?.address ? i.orderDetail?.address : 'არ მოიძებნა'}
        </p>

        <hr style={{ color: 'black', border: '0.1px dashed grey' }} />
        {/* 
        <p style={{ margin: 0, textAlign: 'left' }}>
          {i.customer_address?.additionalInstructions
            ? i.customer_address?.additionalInstructions
            : ''}
        </p> */}
        <p style={{ margin: 0, textAlign: 'left' }}>
          პირადი ინფორმაცია:
          <br />
          {i?.customer?.first_name} {i?.customer?.last_name}
          <br />
          {i?.customer?.phone}
        </p>

        <hr style={{ color: 'black', border: '0.1px dashed grey' }} />

        <p style={{ margin: 0, textAlign: 'left' }}>სექტორი და დროის მონაკვეთი:</p>
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            margin: 0,
            textAlign: 'left',
            marginBottom: '30px',
          }}
        >
          {i?.quickshipper?.comment} /{' '}
          {i?.order_date ? new Date(i?.order_date).toLocaleDateString('en-GB') : ''} {i?.timeSlot}
        </p>
        <hr style={{ color: 'black', border: '0.1px dashed black' }} />
        <p style={{ fontSize: '15px', textAlign: 'center', fontWeight: 'bold', margin: 0 }}>
          მადლობა
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Barcode value={i?.tracking_number} height="60px" />
        </div>
      </div>
    </>
  );
}

// Prop validation
ZebraPrintComponent.propTypes = {
  i: PropTypes.object,
};

export default ZebraPrintComponent;
