import { useEffect, useState } from 'react';
import axios from 'axios';

const GitHubVersion = () => {
  const [version, setVersion] = useState('');
  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get(
          `https://api.github.com/repos/${process.env.REACT_APP_NAME}/${process.env.REACT_APP_REPO}/releases/latest`,
          {
            headers: {
              Authorization: `token ${process.env.REACT_APP_TOKEN}`,
            },
          }
        );
        setVersion(response.data.tag_name);
      } catch (error) {
        console.error('Error fetching release version:', error);
        setVersion('Unavailable');
      }
    };

    fetchVersion();
  }, []);

  return <p style={{ marginRight: '20%', marginTop: '10%' }}>{version}</p>;
};

export default GitHubVersion;
